<template>
  <picture :class="cssClass" :loading="loading" :width="width" :height="height">
    <source
      v-if="webpPath"
      :srcset="webpPath"
      type="image/webp"
      :loading="loading"
      :width="width"
      :height="height"
    />
    <source
      v-if="jpegPath"
      :srcset="jpegPath"
      type="image/jpeg"
      :loading="loading"
      :width="width"
      :height="height"
    />
    <img
      :src="fallbackPath"
      :class="cssClass"
      :alt="altText"
      :loading="loading"
      :width="width"
      :height="height"
    />
  </picture>
</template>

<script>
export default {
  name: "AdaptiveImage",
  props: {
    webpPath: String,
    jpegPath: String,
    fallbackPath: String,
    altText: String,
    loading: {
      type: String,
      default: "lazy"
    },
    height: String,
    width: String,
    cssClass: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
